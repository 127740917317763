import { queryCmsContent } from './_api';

export const getDictionaryFromCms = async (
	host: string | string[],
	preview: boolean,
	previewData: Models.PreviewData,
): Promise<Content.DictionaryItem[]> => {
	const query = `{
		dictionary {
			key
			values {
				culture
				value
			}
		}
	}`;
	return new Promise<Content.DictionaryItem[]>((resolve, reject) => {
		queryCmsContent<{
			dictionary: Content.DictionaryItem[];
		}>({
			host,
			query,
			preview,
			previewData,
			method: 'POST',
			redirectAble: false,
		})
			.then((response) => {
				resolve(response.dictionary);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
