import React from 'react';
import logger from 'helpers/logger';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { DXPContentPage } from 'pages/_app';
import type { PageProps } from 'pages/hosts/[origin]/[[...page]]';

const Error404: DXPContentPage<PageProps> = (pageProps) => {
	logger.info('Rendering 404');

	const { page } = pageProps ?? {};
	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} />
		</LayoutFeature>
	);
};

export default Error404;
