import { usePageContext } from 'application/contexts/PageContext';
import cx from 'classnames';
import { Main } from 'ui/components/4-habitats/Main';
import Head from 'next/head';
import { RichText } from 'ui/components/1-atoms/RichText';
import { LogoProps, Logo } from 'ui/components/2-molecules/Logo';
import { Hero } from 'ui/components/3-organisms/Hero';
import { Container } from 'ui/components/4-habitats/Container';
import { Footer } from 'ui/components/4-habitats/Footer';
import { Header } from 'ui/components/4-habitats/Header';

export interface ErrorPageBaseProps {
	statusCode: number;
	message: string;
}
export interface ErrorPageProps extends ErrorPageBaseProps {
	colorTheme: Models.Settings.SiteTheme;
	logoProps: LogoProps;
	siteTitle: string;
	skipToContent: {
		title: string;
		description: string;
	};
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
	statusCode,
	message,
	colorTheme,
	logoProps,
	siteTitle,
	skipToContent,
}) => {
	const PageContext = usePageContext();
	const { themeSettings } = PageContext;
	const title = statusCode?.toString() ?? 'Error';

	return (
		<div className={cx('page-wrapper', { [`theme__${colorTheme}`]: colorTheme })}>
			<Head>
				<meta httpEquiv="cache-control" content="no-cache" />
				<meta httpEquiv="expires" content="0" />
				<meta httpEquiv="pragma" content="no-cache" />
				<meta title={`${siteTitle} - ${title}`} />
			</Head>
			<Header
				logoArea={<Logo {...logoProps} />}
				navigation={undefined}
				toggleMenuButtonText={''}
				searchBoxProps={{
					closeButtonText: '',
					searchButtonText: '',
					searchInputPlaceholder: '',
				}}
				skipToContent={skipToContent}
			/>
			<Main
				renderAsArticle={false}
				header={<Hero heading={title} imageUrl={null} theme={themeSettings?.heroAndBreadcrumbColorTheme} />}
			>
				<div>
					<Container section width="sm" spacingBottom="md">
						<RichText content={message} />
					</Container>
				</div>
			</Main>
			<Footer theme={themeSettings?.footerColorTheme} />
		</div>
	);
};
