import { NextPage } from 'next';
import logger from 'helpers/logger';
import { ErrorPage, ErrorPageBaseProps } from 'ui/components/5-templates/ErrorPage/ErrorPage';
import { LogoProps } from 'ui/components/2-molecules/Logo';
import sites from '../../../Config/sites.json';
import { ConfigSite, ConfigSiteBase, hostMapHelper } from 'helpers/requestHost/requestHost';

export interface GenericErrorProps extends ErrorPageBaseProps {
	hostname: string;
}

const messageEn = `<p lang="en">Something went wrong. Reload the page <a onClick="history.go(0)">Reload</a></p>`;
const messageDk = `<p lang="da">Noget gik galt. Genindlæs siden <a onClick="history.go(0)">Genindlæs</a>`;
const fallbackSite: ConfigSiteBase = {
	theme: 'mim',
	title: 'Miljøministeriet',
};

/**
 * This is used for 500 pages. Meaning we don't have any data, context etc. from server
 * @param pageProps
 * @returns
 */
const ErrorGeneric: NextPage<GenericErrorProps> = (pageProps) => {
	const { statusCode, message, hostname } = pageProps ?? {};
	logger.info(
		`Rendering Generic Error with SSG/SSR. \n statusCode: ${statusCode}. \n message: ${message}. \n hostname: ${hostname}`,
	);

	const configSite = hostMapHelper(hostname, sites as ConfigSite[], fallbackSite);
	const logoProps: LogoProps = {
		heading: configSite.title,
		logoUrl: '/assets/images/logo_mim.svg',
		linkUrl: '/',
		headerColorTheme: 'dark',
	};
	return (
		<ErrorPage
			logoProps={logoProps}
			message={messageDk + messageEn}
			statusCode={statusCode}
			colorTheme={configSite.theme}
			siteTitle={configSite.title}
			skipToContent={{
				title: 'Skip to content',
				description: 'Click to skip navigations and go directly to content section',
			}}
		/>
	);
};

export default ErrorGeneric;
