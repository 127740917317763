import { getPageContentFromCms } from 'application/adapters/cms/pageContentAdapter';
import logger from 'helpers/logger';
export interface GetPageContentProps {
	url: string;
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
}

export const getPageContent = async ({
	url,
	host,
	preview,
	previewData,
}: GetPageContentProps): Promise<Content.PageContent> => {
	const profiler = logger.startTimer();
	return await getPageContentFromCms(url, host, preview, previewData, true)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			throw err;
		})
		.finally(() => {
			profiler.done({ message: 'WEBSITE: getPageContent()', level: 'info' });
		});
};
