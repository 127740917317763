import { getDictionaryFromCms } from 'application/adapters/cms/dictionaryAdapter';

export interface getDictionaryItemsProps {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
}

export const getDictionaryItems = async ({
	host,
	preview,
	previewData,
}: getDictionaryItemsProps): Promise<Content.DictionaryItem[]> => {
	return getDictionaryFromCms(host, preview, previewData);
};
