import { LRUCache } from 'lru-cache';
import logger from './logger';

export interface CacheParams {
	key: string;
	ttlInMinutes?: number;
}
interface NodeCache {
	cache: LRUCache<unknown, unknown, unknown>;
	getCache: <T>(key: string) => T | undefined;
	/**
	 * Cache object for specifik key.
	 * @param key
	 * @param obj
	 * @param ttlInMinutes. If another ttl than the default value set, this can be added. EX: Apply 10 if a TTL of 10 min is wanted. Leave out if not.
	 * @returns void
	 */
	setCache: (key: string, obj: unknown, ttlInMinutes?: number) => void;
	/**
	 * With this method we can check the cache and potentially return that instead of actually do a API request and interrupt the CMS.
	 * @param cacheParams
	 * @param fetchParams
	 * @param fetcher
	 * @returns
	 */
	checkCacheBeforeFetch: <T, P>(
		cacheParams: CacheParams,
		fetchParams: P,
		fetcher: (param: P) => Promise<T>,
	) => Promise<T>;
}

// Documentation for LRUCache: https://github.com/isaacs/node-lru-cache
const options = {
	max: 500,

	// for use with tracking overall storage size
	maxSize: 20000,
	sizeCalculation: (value) => {
		const result = JSON.stringify(value).length;
		return result;
	},

	// how long to live in ms
	ttl: 1000 * 60 * 30, // Let it live for 30 minutes
	ttlResolution: 2000,

	// return stale items before removing from cache?
	allowStale: false,

	updateAgeOnGet: false,
	updateAgeOnHas: false,
};

/**
 * With this the website can cache stuff directly on the node server. Use it sparingly.
 */
export const nodeCache: NodeCache = {
	cache: new LRUCache(options),
	getCache: function <T>(key: string): T {
		if (this.cache.has(key)) return this.cache.get(key);
		return undefined;
	},
	setCache: function (key: string, obj: unknown, ttl = 0): void {
		if (ttl > 0) {
			this.cache.set(key, obj, { ttl: 1000 * 60 * ttl });
		} else {
			this.cache.set(key, obj);
		}
	},
	checkCacheBeforeFetch: function <T, P>(
		cacheParams: CacheParams,
		fetchParams: P,
		fetcher: (param: P) => Promise<T>,
	): Promise<T> {
		const { key, ttlInMinutes } = cacheParams ?? {};
		return new Promise<T>((resolve, reject) => {
			const cache = nodeCache.getCache<T>(key);
			if (cache) {
				logger.info(`Resolving ${fetcher?.name ?? ''}() from cache`);
				resolve(cache);
			} else {
				fetcher(fetchParams)
					.then(async (response) => {
						nodeCache.setCache(key, response, ttlInMinutes);
						resolve(response);
					})
					.catch((err) => {
						reject(err);
					});
			}
		});
	},
};
