import Error404 from 'features/Error/404';
import ErrorGeneric, { GenericErrorProps } from 'features/Error/ErrorGeneric';
import { getDictionaryItems, getDictionaryItemsProps } from 'application/repositories/dictionaryRepository';
import { GetNavigationInterface, getNavigationData } from 'application/repositories/navigationRepository';
import { GetSiteProps, getSite } from 'application/repositories/siteRepository';
import logger from 'helpers/logger';
import { NextPageContext } from 'next';
import { BaseNextUrl, validateHost, requestHostHelper } from 'helpers/requestHost/requestHost';
import { PageProps } from './hosts/[origin]/[[...page]]';
import { GetPageContentProps, getPageContent } from 'application/repositories/pageContentRepository';
import { nodeCache } from 'helpers/nodeCache';
import { minimalError } from 'helpers/error/minimalError';

type PageErrorProps = PageProps & Omit<GenericErrorProps, 'colorTheme' | 'logoProps'>;

function Error(pageProps: PageErrorProps): unknown {
	const { content, statusCode, message: statusMessage, hostname } = pageProps ?? {};
	return (
		<>
			{statusCode === 404 ? (
				<Error404 {...content} {...pageProps} />
			) : (
				<ErrorGeneric {...{ statusCode, message: statusMessage, hostname }} />
			)}
		</>
	);
}

Error.getInitialProps = async (context: NextPageContext) => {
	const { req, res, err } = context;

	try {
		if (req.headers.host === undefined) {
			logger.warn(
				`Error.getInitialProps() was called without a hostname header at URL: [${req?.url}]: ${JSON.stringify(
					req?.headers ?? '',
				)}`,
			);
		}
	} catch (err) {
		logger.error(
			`Error.getInitialProps() was called without a valid hostname header at URL: [${req?.url}]:`,
			{ reqHeaders: req?.headers ?? '' },
			minimalError(err),
		);
		throw err;
	}

	const host = requestHostHelper(
		{
			host: req.headers.host,
			protocol: 'http:',
		} as BaseNextUrl,
		req.headers.host,
	);

	const statusCode = validateHost(req.headers.host) ? err?.statusCode || res?.statusCode || 404 : 400;

	const statusMessage =
		res?.statusMessage?.length > 0
			? res?.statusMessage
			: err?.message ??
			  `Error.getInitialProps() at URL: [${req?.url}] - No statusMessage on res or message on err`;

	if (statusCode === 404) {
		const url = '404';
		try {
			const dictionaryPromise = await nodeCache.checkCacheBeforeFetch<
				Content.DictionaryItem[],
				getDictionaryItemsProps
			>(
				{
					key: 'dictionary',
				},
				{
					host,
				},
				getDictionaryItems,
			);
			const navigationPromise = await nodeCache.checkCacheBeforeFetch<
				Navigation.NavigationItem,
				GetNavigationInterface
			>(
				{
					key: `navigation-${host}`,
					ttlInMinutes: 10,
				},
				{
					host,
				},
				getNavigationData,
			);
			const sitePromise = await nodeCache.checkCacheBeforeFetch<Models.Site, GetSiteProps>(
				{
					key: `site-${host}`,
				},
				{ host: host },
				getSite,
			);
			const contentPromise = await nodeCache.checkCacheBeforeFetch<Content.PageContent, GetPageContentProps>(
				{
					key: `404-${host}`,
				},
				{
					url,
					host,
				},
				getPageContent,
			);
			const toReturn: PageErrorProps = {
				statusCode: statusCode,
				message: statusMessage,
				content: contentPromise,
				navigation: navigationPromise,
				site: sitePromise,
				dictionary: dictionaryPromise,
				hostname: host,
			};
			return toReturn;
		} catch (error) {
			logger.error(
				`Error.getInitialProps() \n Cannot get content for 404 error page at URL: [${req?.url}]`,
				minimalError(error),
			);
			throw error;
		}
	} else {
		const toReturn: GenericErrorProps = {
			statusCode: statusCode,
			message: statusMessage,
			hostname: req.headers.host,
		};

		logger.error(`Error.getInitialProps() error at URL: [${req?.url}]. Returning:`, { toReturn });
		return toReturn;
	}
};

export default Error;
