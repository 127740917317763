import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export const getSiteFromCms = async (
	host: string | string[],
	preview: boolean,
	previewData: Models.PreviewData,
): Promise<Models.Site> => {
	const query = `{
		site: page(path: "/")
		{
			culture
			properties
		}
	}`;
	const profiler = logger.startTimer();

	return await queryCmsContent<{
		site: {
			culture: Content.Culture;
			properties: Omit<Models.Site, 'host' | 'culture' | 'title'> & {
				siteTitle: string;
			};
		};
	}>({
		host,
		query,
		method: 'POST',
		preview,
		previewData,
		redirectAble: false,
	})
		.then((res) => {
			try {
				const toReturn = {
					host: Array.isArray(host) ? host[0] : host,
					culture: res.site?.culture ?? null,
					title: res.site?.properties?.siteTitle ?? null,
					cookiebotId: res?.site?.properties?.cookiebotId ?? null,
					siteimproveId: res?.site?.properties?.siteimproveId ?? null,
					headHtml: res?.site?.properties?.headHtml ?? null,
					bodyHtml: res?.site?.properties?.bodyHtml ?? null,
					fallbackOGImage: res.site?.properties?.fallbackOGImage ?? null,
					cookieConsentGiven: false,
				};
				return toReturn;
			} catch (error) {
				logger.error('getSiteFromCms() error trying to return result of query.', res, query, error);
				throw error;
			}
		})
		.finally(() => {
			profiler.done({ message: 'WEBSITE: getSiteFromCms()', level: 'info' });
		});
};
