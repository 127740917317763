import { getSiteFromCms } from 'application/adapters/cms/siteAdapter';
export interface GetSiteProps {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
}

export const getSite = async ({ host, preview, previewData }: GetSiteProps): Promise<Models.Site> => {
	return getSiteFromCms(host, preview, previewData);
};
