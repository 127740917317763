import logger from 'helpers/logger';
import { queryCmsContent } from './_api';
import { minimalError } from 'helpers/error/minimalError';

export const getPageContentFromCms = async (
	url: string,
	host: string | string[],
	preview: boolean,
	previewData: Models.PreviewData,
	redirectAble: boolean,
): Promise<Content.PageContent> => {
	const query = `{
		root: page(path: "/")
		{
			properties(fields: [
				"footerCards",
				"socialHeading",
				"socialLinks",
				"policyLinks",
				"partnerSites",
				"serviceNavigation",
				"searchPage",
				"languageSelector",
				"themeSettings"
				"siteForms"
			])
		}
		page: page {
			id
			name
			documentType
			url
			properties
			template
			culture
		}
		searchMetadata: searchMetadata
	}`;

	return await queryCmsContent<{
		searchMetadata: Search.MetadataItem[];
		page: Content.Page;
		mainNavigation: Navigation.NavigationItem;
		root: {
			properties?: {
				footerCards?: Umbraco.FooterCard[];
				partnerSites?: Umbraco.FooterCard[];
				serviceNavigation?: Content.Link[];
				socialHeading?: string;
				socialLinks?: Umbraco.SocialLink[];
				policyLinks?: Content.Link[];
				languageSelector?: Content.Link[];
				themeSettings?: Umbraco.ThemeSettings[];
				searchPage?: Content.Link;
				siteForms?: Models.SiteForm[];
			};
		};
	}>({
		host,
		query,
		method: 'POST',
		preview,
		previewData,
		url,
		redirectAble,
	})
		.then((res) => {
			if (!res.page) {
				logger.error('getPageContentFromCms() - res.page is undefined: ', { res });
			}

			const rootProps = res.root.properties;
			const themeSettings = rootProps?.themeSettings ? rootProps?.themeSettings[0]?.properties : null;

			const retVal: Content.PageContent = {
				host: host as string,
				page: res.page,
				serviceNavigation: rootProps?.serviceNavigation ?? null,
				searchPageUrl: rootProps?.searchPage?.url ?? null,
				themeSettings: {
					name: themeSettings?.themeName ?? null,
					color: themeSettings?.themeColor ?? null,
					logoUrl: themeSettings?.logo?.url ?? null,
					headerColorTheme: themeSettings?.headerColorTheme ?? null,
					heroAndBreadcrumbColorTheme: themeSettings?.heroAndBreadcrumbColorTheme ?? null,
					footerColorTheme: themeSettings?.footerColorTheme ?? null,
					favicon: {
						lightMode: themeSettings?.faviconLightMode?.url ?? null,
						darkMode: themeSettings?.faviconDarkMode?.url ?? null,
					},
				},
				footer: {
					cards: rootProps?.footerCards.map((card) => card.content.properties) ?? null,
					partnerSites: rootProps?.partnerSites[0]?.content?.properties ?? null,
					socialHeading: rootProps?.socialHeading ?? null,
					socialLinks:
						rootProps?.socialLinks?.map((item: Umbraco.SocialLink): Models.SocialLink => {
							return {
								type: item.content.properties.socialLinkType?.toLowerCase() as Models.SocialType,
								title: item.content.properties.socialLinkUrl.name,
								...item.content.properties.socialLinkUrl,
							};
						}) ?? null,
					policyLinks: rootProps?.policyLinks ?? null,
					languageSelector: rootProps.languageSelector ?? null,
				},
				siteForms: rootProps?.siteForms ?? null,
				searchMetadata: res.searchMetadata,
			};

			return retVal;
		})
		.catch((err) => {
			if (err.response.status === 404) {
				logger.debug('getPageContentFromCms() Not Found: ', minimalError(err));
			} else if (
				err.response.status === 301 ||
				err.response.status === 302 ||
				err.response.status === 307 ||
				err.response.status === 308
			) {
				logger.debug('getPageContentFromCms() Redirect: ', minimalError(err));
			} else {
				logger.error('getPageContentFromCms() error: ', minimalError(err));
			}
			throw err;
		});
};
